export default {
  text: '#000000',
  primary: '#F5CA17',
  secondary: '#EF462A',
  tertiary: '#481022',
  background: '#FBAF3F',
  brandedBlue: '#02368F',
  backgroundSecondary: '',
  light: '#b3afaf',
  dark: '#3b3a3a'
}
