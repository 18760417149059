export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.navItem': {
      flex: [0, 0, 0, 1],
      padding: ['1rem 0', '1rem 0', '1rem 0', '1rem']
    },
    '.navMenuOpen': {
      backgroundColor: 'primary'
    },
    '.navMenuLogo': {
      background: 'primary',
      borderBottom: '2px solid black'
    },
    '.containerScrolled': {
      backgroundColor: 'primary',
      borderBottom: '2px solid black',
      '.navItem a': {
        color: 'dark'
      }
    },
    '.navItem': {
      flex: 'unset!important',
      py: '4px'
    },
    '.navItem a': {
      ':hover': {
        color: 'secondary'
      }
    }
  },

  '.navItem': {
    flex: 'unset!important',
    ':hover': {
      color: 'primary'
    }
  },

  scrollProgressBarV1: {
    '.progressBar': {
      backgroundColor: 'black'
    }
  },

  footer: {
    borderTop: '2px solid',
    borderColor: 'secondary',
    backgroundColor: 'tertiary',
    img: {
      padding: ['0 3.5rem', '', '0']
    },
    '.multiButtonContainer': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      a: {
        display: 'inline-block',
        margin: [0, '', '0.5rem'],
        padding: ['0'],
        width: ['42%', '', 'auto']
      }
    },
    a: {
      fontSize: '18px!important',
      color: 'white!important',
      svg: {
        width: 24,
        height: 24,
        margin: '0 0.5rem'
      }
    }
  },

  ctaWidget: {
    display: ['flex'],
    background: 'transparent',
    border: 'none',
    boxShadow: 'none!important',
    '#call-cta': {
      mb: 2
    },
    a: {
      border: '2px solid',
      borderColor: '#C71225',
      background: '#02368F',
      borderRadius: '100%'
    },
    'a:hover': {
      'svg path': {
        fill: 'white'
      }
    },
    'svg path': {
      fill: '#F5CA17!important'
    },
    '#reservations-cta': {
      display: ['flex', 'flex', 'none', 'none']
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['3rem', '', '4rem'],
    textTransform: 'uppercase',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: '1.5rem',
    color: 'white',
    fontWeight: 'bold!important'
  },
  subtitle: {
    color: 'white',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['2rem', '', '2.5rem'],
    marginBottom: '1.5rem',
    '::before': {
      content: "''",
      backgroundColor: 'white',
      width: '10%',
      height: '3px'
    },
    '::after': {
      content: "''",
      backgroundColor: 'white',
      width: '10%',
      height: '3px'
    }
  },
  text: {
    textAlign: 'center',
    p: {
      textAlign: 'center',
      fontSize: 'inherit'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================
  homepageTestimonials: {
    '.menuItemDescription, .menuItemNameContainer': {
      textAlign: 'center'
    },
    '.menuItemDescription': {
      fontSize: '18px'
    },
    '.menuItemContentContainer': {
      maxWidth: 600,
      margin: 'auto'
    }
  },

  homepageHero: {
    '.muteToggle': {
      display: 'none'
    },
    '.videoContainer': {
      height: '100vh'
    },
    '.videoContainer:before': {
      background: 'linear-gradient(rgb(0 0 0 / 40%), rgb(0 0 0 / 2%), rgb(0 0 0 / 0%)) !important'
    },
    height: ['', '', 'calc(100vh + 30px)'],
    backgroundImage:
      "url('https://res.cloudinary.com/gonation/image/upload/v1671469553/sites/parchados/icons_PARCHADOS-14.png')",
    backgroundSize: 'cover',
    padding: ['', '', '0rem'],
    boxShadow: '',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '3rem',
    backgroundColor: '',
    // backgroundImage: "url('https://res.cloudinary.com/gonation/image/upload/v1671469553/sites/parchados/icons_PARCHADOS-14.png')",
    '.lazyload-wrapper': {
      padding: '2.5%',
      width: ['100%', '', '50%'],
      display: 'flex',
      justifyContent: 'center',
      '.image': {
        boxShadow: ``,
        maxWidth: ['100%', '500px', '620px'],
        objectFit: 'cover',
        height: ['auto', 'auto', '80%'],
        margin: 'auto'
      }
    },

    '.content': {
      width: ['100%', '', '50%'],
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      height: ['350px', '', '350px'],
      alignSelf: 'center',
      backgroundColor: 'rgba(255, 255, 255, .5)',
      paddingLeft: '1rem'
    },
    '.hero_content_container': {
      border: 'none!important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      maxWidth: 'none'
    },

    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2.5rem', '', '5rem', '6rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      fontSize: ['1.25rem', '', '1.5rem']
    },
    '.ctaButton': {
      backgroundColor: 'brandedBlue',
      fontSize: '1.2rem',
      textTransform: 'uppercase',
      letterSpacing: '2px',
      color: 'primary',
      border: 'none!important',
      padding: '1rem 2rem',
      justifyContent: 'center',
      width: '100%',
      boxShadow: '#000 5px 10px 20px'
    }
  },

  homepageHeroShout: {},

  contentBoxesTitle1: {
    // padding: ['1rem', '2rem 1rem', '2rem'],
    color: 'white',
    padding: '1rem',
    '::before': {
      content: "'1'",
      fontSize: ['5rem', '', '', '10rem'],
      marginRight: '1rem'
    },
    '.section': {
      alignItems: 'flex-start',
      margin: '1rem'
      // maxWidth: ['', '', '70%'],
    },
    '.title': {
      textAlign: 'left',
      fontSize: ['2rem', '3rem', '4.5rem'],
      letterSpacing: '-2px',
      fontFamily: 'display',
      fontWeight: 'bold'
    }
  },

  contentBoxesTitle2: {
    color: 'white',
    variant: 'customVariants.contentBoxesTitle1',
    paddingLeft: '20%',
    '::before': {
      content: "'2'",
      fontSize: ['5rem', '', '', '10rem'],
      marginRight: '1rem'
    }
  },

  contentBoxesTitle3: {
    color: 'white',
    variant: 'customVariants.contentBoxesTitle1',
    paddingLeft: '40%',
    '::before': {
      content: "'3'",
      fontSize: ['5rem', '', '', '10rem'],
      marginRight: '1rem'
    }
  },

  homepageShout: {
    backgroundColor: 'background',
    '.title': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'black']
    },
    '.text': {
      fontFamily: 'Handy Casual Condensed',
      fontSize: ['1.6rem', '', '2rem'],
      order: ['4', '', '3'],
      border: ['', '', 'solid 9px'],
      borderColor: ['', '', 'black'],
      fontFamily: 'display',
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      backgroundColor: ['black', '', '', 'secondary'],
      color: ['secondary', '', '', 'dark']
    },
    '.date': {
      backgroundColor: '#202020',
      border: ['', '', '', '#ffffff solid 9px'],
      borderColor: ['', '', '', 'black'],
      fontSize: '0.8rem'
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'black'],
      order: ['', '', '4']
    },
    borderTop: '2px solid black',
    borderBottom: '2px solid black',
    margin: '0rem 0rem',
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background'
    }
  },

  homepageAbout: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column!important',
    alignItems: 'center!important',
    // maxWidth: '475px',
    mx: 'auto',
    '.content': {
      border: 'solid 2px',
      backgroundColor: 'secondary',
      alignItems: 'center',
      color: 'white',
      //   borderStyle: 'dotted',
      borderWidth: '10px',
      borderColor: 'dark',

      padding: '2rem',
      marginBottom: '1rem',
      width: '100%'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2.5rem', '', '3rem']
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      p: {
        color: 'white'
      }
    },
    '.lazyload-wrapper': {
      maxHeight: 'none'
    },
    '.imageContainer': {
      display: 'flex',
      justifyContent: 'center',
      padding: '0!important',
      width: '100%',
      img: {
        padding: '0!important',
        margin: '0!important',
        width: '100%',
        objectFit: 'contain'
      }
    },
    '.ctaButton': {
      backgroundColor: 'brandedBlue',
      borderColor: 'tertiary',
      borderWidth: '2px',
      color: 'primary',
      transition: 'all .3s ease-in-out',
      margin: 0,
      ':hover': {
        color: 'brandedBlue',
        backgroundColor: 'primary',
        transition: 'all .3s ease-in-out'
      }
    }
  },

  homepageEvent: {
    variant: 'customVariants.homepageAbout',
    '.imageContainer': {
      display: 'flex',
      justifyContent: 'center',
      padding: '0!important',
      width: '100%',
      img: {
        padding: '0!important',
        margin: '0!important',
        width: '100%',
        objectFit: 'contain',
        maxHeight: ['none', '', '', '', '60vh']
      },
      order: -1
    },
    '.content': {
      alignItems: 'center',
      color: 'white',
      //   borderStyle: 'dotted',

      padding: '2rem',
      marginBottom: '1rem',
      width: '100%'
    },

    '.ctaButton': {
      backgroundColor: 'brandedBlue',
      borderColor: 'tertiary',
      borderWidth: '2px',
      color: 'primary',
      transition: 'all .3s ease-in-out',
      minWidth: '200px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 0,
      ':hover': {
        color: 'brandedBlue',
        backgroundColor: 'primary',
        transition: 'all .3s ease-in-out'
      }
    }
  },

  homepageSauce: {
    variant: 'customVariants.homepageAbout'
  },

  homepageGallery: {
    variant: 'customVariants.homepageAbout'
  },
  homepageInstagram: {
    '.logoAndTextContainer ': {
      // margin: '3rem 0rem 2rem',
      display: 'none'
    },
    '.iFrameContainer': {
      width: ['100%', '100%', '100%']
    }
  },

  homepageContactUs: {
    variant: 'customVariants.homepageAbout',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    padding: ['3rem 1rem', '', '8rem 1rem'],
    '.title': {
      variant: 'customVariants.title',
      order: '1'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      order: '2'
    },
    '.text': {
      order: '3'
    },
    a: {
      order: '5'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    h1: {
      fontWeight: 'bold!important'
    },
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary',
      fontWeight: 'bold'
    },
    '.title, .text': {
      color: 'dark'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumName': {
      color: 'white'
    },
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    },
    '.albumsCTAActive': {
      opacity: 1,
      backgroundColor: 'secondary',

      '.albumTitle': {
        color: 'dark'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset'
    },
    '.eventDays, .eventDate': {
      color: 'white'
    },
    'h4, h5': {
      color: 'white'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      textTransform: 'uppercase',
      color: 'primary'
    },
    '.address:hover, .phone:hover': {
      color: 'secondary'
    },
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '9rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: 'bold',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    }
  },

  locationMap: {
    order: '3',
    h3: {
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '1.75rem', '2rem'],
      color: 'white',
      marginBottom: '1.5rem',
      borderBottom: '2px solid',
      borderColor: 'primary'
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid',
      color: 'white'
    },
    'a, .time, .closed': {
      color: 'white'
    }
  },

  // ? ========================
  // ? =====  Team pages  =====
  // ? ========================

  teamMenu: {
    marginTop: ['5rem', '', '8rem'],
    padding: ['0rem 1rem', '0rem 1rem', '0rem 1rem', '0rem 1rem'],
    '.boxesHeading': {
      display: 'none'
    },
    '.boxesContainer': {
      // flexDirection: 'column',
      maxWidth: '767px',
      margin: '0rem auto'
    },
    '.box': {
      width: ['', '', 'calc(50% - 1rem)'],
      ':hover': {},
      a: {
        // display: 'flex',
        // flexDirection: ['', '', 'row'],
      }
    },
    '.textContent': {
      position: 'static'
    }
  },
  teamPage: {
    '.imageContainer': {
      width: ['', '', '40%']
    },
    '.contentRow': {
      width: ['', '', '60%'],
      padding: ['1rem', '1.5rem', '2.5rem', '3rem']
    },
    '.title': {
      color: 'white'
    },
    '.subtitle': {
      color: 'primary'
    }
  }
}
