export default {
  heading: 'Rubik, sans-serif',
  body: 'Rubik, sans-serif',
  monospace: 'Rubik, sans-serif',
  display: 'Rubik, serif',
  display2: 'Rubik, sans-serif',
  display3: 'Rubik',
  googleFonts: ['Rubik', 'Rubik'],
  customFamilies: [],
  customUrls: []
}
