const variantName = 'ctaWidgetV1'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      backgroundColor: 'secondary',
      position: 'fixed',
      bottom: ['.5rem', '', '0.5rem'],
      right: ['.5rem', '', '0.5rem'],
      width: ['unset', '', 'unset'],
      border: ['1px solid', '', ''],
      flexDirection: ['', '', 'column'],
      flexWrap: 'wrap',
      boxShadow: ['', '', '2px 2px 8px black'],
      zIndex: '999',
      color: 'primary',
      border: '1px solid',
      borderColor: 'primary',

      // ?============================
      // ?=========  Button  =========
      // ?============================

      // TODO: change a to a variantName and give it is own variant rather than nested
      a: {
        minWidth: '50%',
        flexGrow: '1',
        padding: ['0.75rem', '', '1rem'],
        justifyContent: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: [2, 2, 2, 2],
        transition: 'all ease-in-out 0.5s',
        borderBottom: ['1px solid'],

        // ?== Nested Elements ==
        svg: {
          marginRight: ['unset', '', 'unset'],
          path: {
            fill: 'primary'
          }
        },

        // ?== Psuedo Elements ==
        '&:hover': {
          backgroundColor: 'secondary'
        },

        ':last-child': {}
      }
    },

    ctaLabel: {
      display: ['none', '', ''] // *hides text of label on tablet and just shows the icon
    }
  }
}
